import { getTokenExpirationTime } from "@/features/Authentication/helpers/getTokenExpirationTime";

// This checks if the token is expired, unparseable, unexistent or invalid.
export const isTokenValid = (accessToken?: string | null) => {
  if (!accessToken) {
    return false;
  }
  try {
    const expirationTime = getTokenExpirationTime(accessToken);
    if (!expirationTime) {
      return false;
    }

    return Date.now() < expirationTime;
  } catch (e) {
    return false;
  }
};
